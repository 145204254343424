<template>
  <div>
    <div class="bg-ghost-white">
      <div class="container-xxl">
        <div class="row">
          <div
            class="col-12 col-lg-8 col-xl-7 d-flex align-items-center my-34 my-lg-60 my-xl-120 order-2 order-lg-1"
            style="z-index: 1"
          >
            <div class="w-100">
              <h1 class="heading-header-1 mb-0 w-xl-90 w-lg-70">
                <span class="text-primary">Отменяем</span> стандартное
                и скучное <span class="text-primary">резюме</span>!
              </h1>
              <SearchComponent
                class="my-60"
                :is-main="true"
              />
              <div
                v-if="!client.isAuthenticated || !client.isSummaryCreated"
                class="d-flex flex-md-row flex-column gap-15 mb-60"
              >
                <div class="col-md-auto col-12">
                  <NuxtLink
                    class="btn btn-primary fs-md-14 fs-16 px-md-24 py-md-0 p-20 w-100 fw-bold rounded-20 h-custom"
                    :to="client.isAuthenticated ? '/applicant/summaries' : '/signup'"
                  >
                    Создать видеоинтервью
                  </NuxtLink>
                </div>
                <div>
                  <button
                    class="btn btn-cultured fs-md-14 fs-16 bg-white w-100 px-md-24 py-md-0 p-20 rounded-20 text-black h-custom"
                    @click="openVideo"
                  >
                    Смотреть видео о <span class="text-primary fw-bold">Sobes.ru</span>
                  </button>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-start w-xl-75 gap-12">
                <AppButton
                  v-for="item in professions"
                  :to="getSearchUrl + `/${item.alias}`"
                  :variant="BtnVariant.Outline"
                >
                  {{ item.title }}
                </AppButton>
                <AppButton
                  :to="getSearchUrl"
                  :variant="BtnVariant.Outline"
                  class="border-1 border-primary"
                >
                  Все {{ searchSource === 1 ? 'вакансии' : 'кандидаты' }}
                </AppButton>
              </div>
            </div>
            <!-- /.header-slider -->
          </div>
          <!-- /.col -->

          <div class="col-12 col-lg-4 col-xl-5 order-1 order-lg-2 d-lg-block d-none">
            <div class="position-relative h-lg-100">
              <img
                src="/public/pages/index/header/heroImage.jpg"
                class="hero-image-img w-lg-auto h-lg-100 hero-image"
                alt=""
              >
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </div>

    <div class="bg-white">
      <div class="container-xxl pt-25 pb-60 overflow-hidden overflow-xxl-visible">
        <div class="d-flex flex-wrap">
          <div
            ref="contentScroll"
            class="d-flex flex-md-row flex-column justify-content-center align-items-center gap-20 mb-60 mx-auto w-md-auto w-100"
          >
            <span class="heading-text-1 text-primary">Как это работает</span>
            <div class="d-flex gap-10">
              <AppButton
                class="fw-bold"
                :variant="BtnVariant.Tertiary"
                :active="tab === 1"
                @click="clickClient"
              >
                для соискателя
              </AppButton>
              <AppButton
                class="fw-bold"
                :variant="BtnVariant.Tertiary"
                :active="tab === 2"
                @click="clickCompany"
              >
                для работодателя
              </AppButton>
            </div>
          </div>
          <h2 class="heading-section-1 text-dark mb-43 col-12">
            <span class="text-primary">Начни</span>
            поиск {{ tab === 1 ? forClient.title : forCompany.title }} по новому, это
            <span class="text-primary">просто</span>!
          </h2>

          <div class="col-12 col-xl-5 order-2 order-xl-1 d-flex flex-column accordion accordion-number pe-xl-43 pe-0">
            <div
              v-for="(item, index) in getSteps"
              class="accordion-item shadow rounded-20 h-100"
            >
              <div class="accordion-button h-100">
                <div class="position-relative d-flex w-100 align-items-center">
                  <div
                    class="accordion-number d-sm-flex d-none"
                    :class="getColorNum(index+1)"
                  >
                    {{ index+1 }}
                  </div>
                  <div class="accordion-content px-0">
                    <div class="d-sm-block d-flex flex-row">
                      <div
                        class="accordion-number d-sm-none d-flex"
                        :class="getColorNum(index+1)"
                      >
                        {{ index+1 }}
                      </div>
                      <h3 class="accordion-header p-0">
                        {{ item.title }}
                      </h3>
                    </div>
                    <div
                      class="accordion-body pe-xl-10"
                      style="text-align: justify"
                    >
                      {{ item.body }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="position-relative bg-white d-flex align-items-center justify-content-center col-12 col-xl-7 p-0 mb-34 mb-xl-0 order-1 order-xl-2">
            <video
              ref="videoPreview"
              class="bg-transparent h-100 mw-100"
              style="object-fit: cover; border-radius: 46px;"
              autoplay
              loop
              playsinline="true"
              :src="`${tab === 1 ? forClient.video : forCompany.video}#t=0.001`"
            />
            <svg
              height="58"
              width="75"
              class="text-primary svg-inject position-absolute how-it-work-figure-2"
            >
              <use xlink:href="/public/icons/figures-sprite.svg#circles" />
            </svg>
            <svg
              height="43"
              width="81"
              class="text-primary svg-inject position-absolute how-it-work-figure-3"
            >
              <use xlink:href="/public/icons/figures-sprite.svg#waves" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="container-xxl py-60">
      <div class="d-grid w-md-70 w-100 mb-34 mx-auto gap-12">
        <h2 class="heading-section-3 text-dark text-center mb-0">
          Почему <span class="text-primary">СОБЕС</span>?
        </h2>
        <div class="heading-text-1 lh-base text-dark text-center">
          {{ tab === 1 ? forClient.whysobes.head : forCompany.whysobes.head }}
        </div>
      </div>

      <div class="row mx-auto w-custom">
        <div class="col-custom my-12 px-md-5 px-0">
          <div class="card card-icon h-100">
            <div class="d-flex">
              <div class="card-box-icon bg-accent text-white flex-shrink-0">
                <svg class="svg-inject size-arrow">
                  <use xlink:href="/public/icons/arrow-left-bold.svg#arrow-left" />
                </svg>
              </div>
              <h3 class="card-title ms-25">
                Просто
              </h3>
            </div>
            <p
              class="card-text text-wrap"
              style="text-align: justify"
            >
              {{ tab === 1 ? forClient.whysobes.blocks[0] : forCompany.whysobes.blocks[0] }}
            </p>
          </div>
          <!-- /.card-icon-component -->
        </div>
        <!-- /.col -->

        <div class="col-custom my-12 px-md-10 px-0">
          <div class="card card-icon h-100">
            <div class="d-flex">
              <div
                class="card-box-icon text-white flex-shrink-0"
                style="background: green"
              >
                <svg class="svg-inject size-arrow">
                  <use xlink:href="/public/icons/arrow-left-bold.svg#arrow-left" />
                </svg>
              </div>
              <h3 class="card-title ms-25">
                Быстро
              </h3>
            </div>
            <p
              class="card-text text-wrap"
              style="text-align: justify"
            >
              {{ tab === 1 ? forClient.whysobes.blocks[1] : forCompany.whysobes.blocks[1] }}
            </p>
          </div>
          <!-- /.card-icon-component -->
        </div>
        <!-- /.col -->

        <div class="col-custom offset-custom my-12 px-md-5 px-0">
          <div class="card card-icon h-100">
            <div class="d-flex">
              <div class="card-box-icon bg-primary text-white flex-shrink-0">
                <svg class="svg-inject size-arrow">
                  <use xlink:href="/public/icons/arrow-left-bold.svg#arrow-left" />
                </svg>
              </div>
              <h3
                class="card-title ms-25"
                style="word-wrap: anywhere;"
              >
                Инновационно
              </h3>
            </div>
            <p
              class="card-text text-wrap"
              style="text-align: justify"
            >
              {{ tab === 1 ? forClient.whysobes.blocks[2] : forCompany.whysobes.blocks[2] }}
            </p>
          </div>
          <!-- /.card-icon-component -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>

    <div class="container-xxl pb-90">
      <div class="accordion accordion-number">
        <div
          v-for="(item, index) in getFAQ"
          class="accordion-item rounded-20 mt-15"
        >
          <button
            class="accordion-button rounded-20"
            :class="openAccordion === index ? '' : 'collapsed'"
            type="button"
            aria-expanded="false"
            @click="openAccordion = index"
          >
            <div class="position-relative d-flex w-100">
              <div class="accordion-content py-0 pe-10">
                <h3 class="accordion-header pe-43">
                  {{ item.title }}
                </h3>
                <div
                  class="accordion-collapse"
                  :class="openAccordion === index ? '' : 'collapse'"
                >
                  <div
                    v-if="index !== getFAQ.length-1"
                    class="accordion-body"
                  >
                    {{ item.body }}
                  </div>
                  <div
                    v-else-if="tab === 1"
                    class="accordion-body"
                  >
                    Вы можете обратиться к нам через
                    <a
                      href="/help?theme=3"
                      class="text-primary text-decoration-none"
                    >
                      форму техподдержки
                    </a>.
                    Если возникли ещё вопросы, можете их найти в разделе
                    <a
                      href="/help?theme=1"
                      class="text-primary text-decoration-none"
                    >
                      помощь для соискателей
                    </a>.
                  </div>
                  <div
                    v-else
                    class="accordion-body"
                  >
                    Да, у нас есть успешные истории и положительные отзывы от компаний, которые сэкономили время и нашли идеальных сотрудников через нашу платформу. Мы можем поделиться этими кейсами с вами для более подробной информации. Напишите нам на почту <span class="text-primary">offer@sobes.ru</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-arrow">
              <svg
                width="20"
                height="11"
                viewBox="0 0 20 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inject-url="https://peterdraw.studio/ziro-html/assets/icons/chevron-down.svg"
                class="svg-inject svg-icon"
              >
                <path d="M19.6273 1.77879L10.6273 9.77878C10.4448 9.9391 10.2102 10.0275 9.96728 10.0275C9.72437 10.0275 9.48977 9.9391 9.30728 9.77878L0.307278 1.77879C0.121445 1.6003 0.0116842 1.35696 0.000880246 1.09952C-0.00992376 0.842077 0.0790634 0.5904 0.249287 0.396964C0.419511 0.203528 0.657833 0.0832638 0.91456 0.0612484C1.17129 0.039233 1.42661 0.117165 1.62728 0.278796L9.96728 7.68878L18.3073 0.278796C18.4046 0.185284 18.5199 0.112452 18.6462 0.0646883C18.7724 0.0169249 18.9071 -0.00478066 19.0419 0.000879482C19.1768 0.00653962 19.3091 0.0394483 19.4309 0.0976224C19.5528 0.155797 19.6615 0.238031 19.7507 0.339371C19.8399 0.440711 19.9076 0.559058 19.9498 0.687279C19.9921 0.8155 20.0079 0.950941 19.9963 1.08544C19.9848 1.21994 19.9462 1.35071 19.8827 1.46987C19.8193 1.58903 19.7324 1.69412 19.6273 1.77879Z" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="container-xxl pb-90">
      <LinkingPopularProfessions
        :city="searchCity"
        :source="searchSource"
      />
    </div>

    <VueFinalModal
      v-model="modalShow"
      content-class="modal-dialog modal-dialog-centered"
      style=""
      display-directive="show"
      @closed="closeVideo"
    >
      <div class="modal-content rounded-20 mx-auto" style="max-width: 347px">
        <div class="modal-header">
          <button
            class="btn-close"
            type="button"
            aria-label="Закрыть"
            title="Закрыть"
            @click="closeVideo"
          />
        </div>
        <div class="modal-body p-10" style="height: 668px;">
          <iframe
            v-if="modalShow"
            ref="modalVkFrame"
            class="rounded-10 w-100"
            src="https://vkvideo.ru/video_ext.php?oid=-226000618&id=456239069&hd=2&autoplay=1"
            width="325"
            height="646"
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </div>
    </VueFinalModal>
  </div>
</template>

<script setup lang="ts">
import { useClient } from "~/stores/client";
import type { Profession } from "~/entities/profession";
import { VueFinalModal } from 'vue-final-modal';
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { SearchSource } from "~/composables/enums/SearchSource";
import { getProfileRoute } from "~/composables/ClientRedirectToProfile";

const router = useRouter();
const client = useClient();

useHead({
  bodyAttrs: {
    class: 'page__homepage'
  }
})

//-----VARIABLES-----\\
const forClient = {
  video: '//static.sobes.ru/promo-applicant.mp4',
  title: 'работы',
  steps: [
    {
      title: 'Запиши видеорезюме',
      body: 'Вы уже знаете, что первое впечатление имеет большое значение. Вместо традиционных текстовых CV, вы запишите видео, в котором представьте себя с наилучшей стороны.'
    },
    {
      title: 'Знакомство с вакансиями',
      body: 'Отправляйте отклики работодателям, которые вас заинтересовали. С нетерпением ожидайте приглашений на интервью.'
    },
    {
      title: 'Получение работы ',
      body: 'После успешных интервью и предложений о работе, выберите самую подходящую позицию и отправляйтесь на новое рабочее приключение.'
    }
  ],
  whysobes: {
    head: 'СОБЕС - это революционный стартап, который перевернет мир найма! Забудьте о скучных резюме, ведь теперь вы можете показать свой характер, энергию и профессионализм в видеоинтервью, сделав первое впечатление, которое запомнится навсегда.',
    blocks: [
      'Процесс использования СОБЕС максимально прост и интуитивно понятен. Просто запишите видео и начните свой путь к новой работе.',
      'Сэкономьте время на написание резюме и заявлений. Просто представьтесь на видео и опубликуйте с любого устройства.',
      'СОБЕС предоставляет вам уникальный способ выделиться. Ваши навыки и личность могут проявиться на видео с лучшей стороны.'
    ]
  },
  questions: [
    {
      title: 'Что такое "СОБЕС" и как я могу использовать эту платформу для поиска работы?',
      body: '"СОБЕС" - это инновационная платформа для поиска работы, которая позволяет вам создавать видео-резюме, чтобы продемонстрировать свои навыки и личность работодателям. Вы можете создать видео-презентацию и просматривать видео-вакансии от компаний.',
    },
    {
      title: 'Чем видео-резюме лучше обычных текстовых?',
      body: 'Видео-резюме позволяют вам выразить себя лучше, чем обычные текстовые документы. Вы можете демонстрировать свои коммуникативные навыки, энтузиазм и профессиональный стиль намного лучше, чем просто словами. Это может помочь вам выделиться среди других кандидатов.',
    },
    {
      title: 'Как обеспечивается моя конфиденциальность на платформе "СОБЕС"?',
      body: 'Ваша конфиденциальность очень важна для нас. Вы можете  отключить видимость вашего видео-резюме в поиске или сделать доступ только по ссылке. Только те работодатели, которые вас пригласили могут видеть ваш номер телефона.',
    },
    {
      title: 'Сколько стоит использование "СОБЕС" для соискателей?',
      body: 'Использование "СОБЕС" для соискателей абсолютно бесплатно. Вы можете создавать и хранить своё видео-резюме на платформе без каких-либо затрат. Это значит, что вы можете начать использовать нашу платформу без финансовых обязательств.',
    },
    {
      title: 'Как мне связаться с вами и предложить идеи для развития платформы или указать на неисправность?',
    },
  ]
};
const forCompany = {
  video: '//static.sobes.ru/promo-applicant.mp4',
  title: 'сотрудника',
  steps: [
    {
      title: 'Запиши видеовакансию',
      body: 'Для начала взаимодействия с кандидатами, необходимо записать видеовакансию. Это важно, чтобы кандидаты познакомились с вами и вашей культурой.'
    },
    {
      title: 'Знакомство с кандидатами',
      body: 'Быстро и удобно просматривайте видеопрезентации кандидатов, получая лучшее представление о их навыках и личности. Это упрощает процесс выбора соискателя.'
    },
    {
      title: 'Приглашение кандидата',
      body: 'Кандидату отправляется приглашение на видеоинтервью или онлайн собеседование, где он сможет более подробно представить себя и обсудить свой опыт и навыки.'
    }
  ],
  whysobes: {
    head: 'СОБЕС - это новый взгляд на найм, который делает процесс более эффективным и интересным для всех сторон. Не упустите шанс улучшить свой опыт найма с нами!',
    blocks: [
      'СОБЕС создает прозрачное и легкое в использовании решение для найма. Просто просматривайте видео и принимайте обоснованные решения.',
      'СОБЕС упрощает процесс найма, позволяя вам быстро просматривать видеопрезентации соискателей, что экономит ваше время и ускоряет найм. ',
      'СОБЕС открывает новые возможности для оценки соискателей. Вы получите более глубокое представление о них, наблюдая за ними на видео.'
    ]
  },
  questions: [
    {
      title: 'Какие преимущества предоставляет "СОБЕС" для работодателей по сравнению с традиционными методами найма?',
      body: '"СОБЕС" предоставляет более глубокое представление о кандидатах через видео-резюме, что помогает лучше оценить их навыки и личность. Это ускоряет процесс найма, сокращает количество неподходящих кандидатов и улучшает точность отбора.',
    },
    {
      title: 'Какие инструменты и функциональность доступны для работодателей на вашей платформе?',
      body: 'Мы предоставляем возможность создавать видео-вакансии, просматривать видео-резюме кандидатов, приглашать кандидатов на видеоинтервью со своими вопросами и проводить онлайн собеседование.',
    },
    {
      title: 'Каким образом "СОБЕС" помогает сократить время и затраты на найм персонала?',
      body: '"СОБЕС" снижает временные и финансовые затраты на найм, предоставляя возможность более оперативной и визуальной оценки кандидатов через видео-резюме. Это ускоряет процесс отбора, сокращая расходы на размещение вакансий и проведение традиционных интервью.',
    },
    {
      title: 'Какие тарифы доступны для работодателей на вашей платформе?',
      body: 'На данный момент наша платформа находится в бета-версии, и мы не взимаем плату за её использование. Мы предоставляем доступ бесплатно, чтобы получить обратную связь и улучшить наш сервис. В будущем мы можем рассмотреть различные опции для оплаты, но в данный момент вы можете использовать "СОБЕС" без дополнительных затрат.',
    },
    {
      title: 'Есть ли какие-либо успешные кейсы или отзывы от других компаний, которые уже использовали "СОБЕС" для найма?',
    },
  ]
};

//-----STATE-----\\
const openAccordion = ref<number>(0);
const professions = ref<Profession[]>([]);
const tab = ref<number>(1);
const searchCity = useState<City>('searchCity');
const searchSource = useState<number>('searchSource', () => SearchSource.Vacancies);
const modalShow = ref<boolean>(false);
const modalVkFrame = ref<HTMLVideoElement|null>(null);
const videoPreview = ref<HTMLVideoElement|null>(null);
const contentScroll = ref();

//-----COMPUTED-----\\
const getFAQ = computed<Object[]>(() => {
  if (tab.value === 1) {
    return forClient.questions;
  } else if (tab.value === 2) {
    return forCompany.questions;
  }
  return [];
});
const getSteps = computed<Object[]>(() => {
  if (tab.value === 1) {
    return forClient.steps;
  } else if (tab.value === 2) {
    return forCompany.steps;
  }
  return [];
});
const getSearchUrl = computed(() => {
  return '/' + searchCity.value?.alias + '/' + (searchSource.value === SearchSource.Vacancies ? 'vacancies' : 'summaries');
});

//-----FETCH-----\\
const { data } = await mainFetch('professions', {
  query: {
    type: 'popular',
    limit: 8
  }
});
professions.value = data.value.professions;
if (client.isAuthenticated) {
  console.log(getProfileRoute());
  router.push(getProfileRoute());
}

//-----METHODS-----\\
function getColorNum(index: number) {
  switch(index) {
    case 1:
      return 'bg-blue-violet bg-opacity-10 text-blue-violet';
    case 2:
      return 'bg-persian-blue bg-opacity-10 text-persian-blue';
    default:
      return 'bg-ufo-green bg-opacity-10 text-ufo-green';
  }
}
function openVideo() {
  modalShow.value = true;
}
function closeVideo() {
  modalShow.value = false;
}
/*
function play() {
  showButton.value = !showButton.value;
  if (showButton.value === true) {
    videoPreview.value.pause();
    return;
  } else {
    videoPreview.value.play();
  }
}
*/

function clickClient() {
  tab.value = 1;
  contentScroll.value.scrollIntoView({ behavior: "smooth"});
}
function clickCompany() {
  tab.value = 2;
  contentScroll.value.scrollIntoView({ behavior: "smooth"});
}
</script>

<style scoped lang="scss">
.button-play {
  height: 180px;
  width: 180px;

  @media (max-width: 767px) {
    height: 60px;
    width: 60px;
  }
}

.video-sobes {
  object-fit: cover;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.svg-size {
  width: 25px;
  height: 25px;
}
.size-arrow {
  width: 50px;
  height: 50px;
}

.h-custom {
  @media (min-width: 768px) {
    height: 40px !important;
    line-height: 36px !important;
  }
}

.w-custom {
  @media (min-width: 1500px) {
    width: 85%;
  }

  @media (max-width: 1279px) {
    width: 80%;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
}

.col-custom {
  @media (min-width: 1280px) {
    width: 33.3333%;
  }

  @media (max-width: 1279px) {
    width: 50%;

    &.offset-custom {
      margin-left: 25%;
    }
  }

  @media (max-width: 1080px) {
    &.offset-custom {
      width: 55%;
      margin-left: 22%;
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    &.offset-custom {
      width: 100%;
      margin-left: 0;
    }
  }

}
</style>
